import {KTags} from '@/components/tags/KTags';
import {KTagType} from '@/components/tags/types';
import {useMemo} from 'react';
import {NotificationBadgeProps} from './types';

export const notificationTagsDisplayName: Record<string, string> = {
  ACCOUNT_SUPPORT: 'Account Support',
  DATA_QUALITY: 'Data Quality',
  NON_COMPLIANCE: 'Non-Compliant',
};

export const resolveNotificationTags = (tags: string[], tid: string): KTagType[] =>
  tags?.map((tagId) => ({
    id: `${tid}-${tagId}`,
    label: notificationTagsDisplayName[tagId] || `${tagId}???`,
    selected: true,
    removable: false,
    chipProps: {className: `notification-tag no-hover ${tagId}`},
    'data-testid': `${tid}-${tagId}`,
  }));

export type NotificationTagsProps = Omit<NotificationBadgeProps, 'onChangeMessage'>;

/**
 * Notification show tags as expanded or collapsed.
 *
 * @see card.theme.tsx for background colors using tag id string as className.
 */
export const NotificationTags = ({message, variant = 'collapsed'}: NotificationTagsProps) => {
  const tid = message.id;
  const tags = useMemo(() => resolveNotificationTags(message.tags ?? [], tid), [message.tags, tid]);

  return (
    <>
      {tags.length > 0 && (
        <>
          {variant === 'expanded' ? (
            <div className={'notification-tags'}>
              <KTags tags={tags} onChangeTag={() => {}} data-testid={`${tid}-tags`} />
            </div>
          ) : (
            <div className={'notification-tags-collapsed'} data-testid={`${tid}-tags-collapsed`}>
              {tags.map((tag) => (
                <div
                  key={tag.id}
                  className={`notification-tag-collapsed ${tag.chipProps?.className}`}
                  title={typeof tag.label === 'string' ? tag.label : undefined}
                  data-testid={`${tag['data-testid']}-collapsed`}
                ></div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
