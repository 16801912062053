'use client';

import {useUser} from '@auth0/nextjs-auth0/client';
import {PropsWithChildren} from 'react';

/** Renders children if user session is present aka if logged in */
export function RenderIfUser({children}: PropsWithChildren) {
  const {user} = useUser();
  if (user) {
    return children;
  } else {
    return null;
  }
}
