import {attemptSync} from '@/util/attempt';
import {
  PassportClaim,
  Permission,
  Resource,
  checkPermission,
  platformResourceTree,
  resourceTypeIdentity,
  resourceTypeOrganization,
  resourceTypeSite,
  resourceTypeSponsor,
  resourceTypeStudy,
} from '@koneksahealth/ts-authorizer';

type ParentScopeByResource = {
  organization: 'none';
  sponsor: 'organization';
  study: 'sponsor' | 'organization';
  site: 'organization';
  studySite: 'study';
  identity: 'none';
  other: 'other';
  none: 'none';
  participant: 'studySite';
};

type OtherResourceScopes = {
  // study
  study_configuration: 'study';
  study_device_configuration: 'study';
  localization: 'study';
  report: 'study' | 'studySite' | 'participant';
  user: 'organization' | 'sponsor' | 'study' | 'studySite' | 'identity';
  platform_notification_preferences: 'organization' | 'sponsor' | 'study' | 'studySite' | 'identity';
  platform_notification: 'organization' | 'sponsor' | 'study' | 'studySite' | 'identity';

  assessment: 'studySite';
  assessment_response: 'participant';
  participant: 'participant';
  participant_status: 'participant';
  participant_pii: 'participant' | 'studySite';
  device_participant: 'participant';
  clinician_reported_outcome: 'participant';
  participant_visit: 'participant';

  ui_organization_dashboard: 'organization';
  ui_sponsor_dashboard: 'sponsor';
  ui_study_dashboard: 'study';
  ui_site_dashboard: 'site';
};

type RequiredScopeByResource = {
  organization: {orgId: string};
  sponsor: {orgId: string; sponsorId: string};
  study: {orgId: string; sponsorId: string; studyId: string};
  site: {orgId: string} | {orgId: string; sponsorId: string; studyId: string; siteId: string};
  studySite: {orgId: string; sponsorId: string; studyId: string; siteId: string};
  identity: {identityId: string};
  participant: {orgId: string; sponsorId: string; studyId: string; siteId: string; identityId: string};
  other: Resource[];
  none: {};
};

type ResourceFromPermission<T extends Permission> = T extends `${infer A}:${infer R}`
  ? R extends keyof RequiredScopeByResource // one of the core resources
    ? A extends 'create' // if create, need to create in the parent resource
      ? ParentScopeByResource[R]
      : A extends 'associate' // if associate, need to associate site to study
        ? 'study'
        : R
    : R extends keyof OtherResourceScopes // not one of the core resources
      ? OtherResourceScopes[R]
      : 'other'
  : 'other';

export type ScopeForPermission<T extends Permission> = RequiredScopeByResource[ResourceFromPermission<T>];

function buildTreeForType(scope: RequiredScopeByResource[keyof RequiredScopeByResource]): Resource[] {
  if (Array.isArray(scope)) {
    return scope;
  }

  const resourceTree: Resource[] = [];

  if ('orgId' in scope && !!scope.orgId) {
    resourceTree.push({resourceType: resourceTypeOrganization, resourceID: scope.orgId});
  }

  if ('sponsorId' in scope && !!scope.sponsorId) {
    resourceTree.push({resourceType: resourceTypeSponsor, resourceID: scope.sponsorId});
  }

  if ('studyId' in scope && !!scope.studyId) {
    resourceTree.push({resourceType: resourceTypeStudy, resourceID: scope.studyId});
  }

  if ('siteId' in scope && !!scope.siteId) {
    resourceTree.push({resourceType: resourceTypeSite, resourceID: scope.siteId});
  }

  if ('identityId' in scope && !!scope.identityId) {
    resourceTree.push({resourceType: resourceTypeIdentity, resourceID: scope.identityId});
  }

  return resourceTree;
}

export function hasPermissionOnResource<T extends Permission>(
  passportClaim: PassportClaim,
  action: T,
  scope: ScopeForPermission<T>,
) {
  const [err] = attemptSync(() => checkPermission(passportClaim, action, buildTreeForType(scope)));

  return !err;
}

export function hasPermissionsOnResource<T extends Permission>(
  passportClaim: PassportClaim,
  actions: T[],
  scope: ScopeForPermission<T>,
) {
  return actions.every((a) => {
    const [err] = attemptSync(() => checkPermission(passportClaim, a, buildTreeForType(scope)));
    return !err;
  });
}

export function hasPlatformWidePermission<T extends Permission>(passportClaim: PassportClaim, action: T) {
  const [err] = attemptSync(() => checkPermission(passportClaim, action, platformResourceTree));

  return !err;
}

export function hasPlatformWidePermissions<T extends Permission>(passportClaim: PassportClaim, actions: T[]) {
  return actions.every((a) => {
    const [err] = attemptSync(() => checkPermission(passportClaim, a, platformResourceTree));

    return !err;
  });
}

/* Passport Examples

Super Admin:
{
  "iss": "https://auth.dev.koneksahealth.com/",
  "sub": "d4125404-d877-4c95-9c3e-d26dae106c43",
  "exp": 1668002263,
  "nbf": 1668001963,
  "iat": 1668001963,
  "jti": "f7b13f7f-fe0e-4655-bd08-d9869429694f",
  "koneksa/passport": {
    "i": "d4125404-d877-4c95-9c3e-d26dae106c43",
    "r": [
      {
        "id": "61db58d2-ba23-507a-b38d-8dd4aa646203",
        "n": "self",
        "s": {
          "oid": "964bb7cb-017c-5a35-bcf1-5ddc8f958cb5",
          "iid": "d4125404-d877-4c95-9c3e-d26dae106c43"
        },
        "p": [
          "edit:user",
          "view:user"
        ]
      },
      {
        "id": "f6eb8294-a478-11ec-b909-0242ac120002",
        "n": "Platform Super Administrator",
        "s": {
          "oid": "46fd9dca-70df-5df7-a4df-b984ec8b9c86"
        },
        "p": [
          "create:organization",
          "edit:organization",
          "delete:organization",
          "view:organization",
          "create:sponsor",
          "edit:sponsor",
          "delete:sponsor",
          "view:sponsor",
          "create:study",
          "edit:study",
          "delete:study",
          "view:study_configuration",
          "view:study",
          "create:site",
          "edit:site",
          "delete:site",
          "associate:site",
          "view:site",
          "edit:study_device_configuration",
          "assign:device_participant",
          "unassign:device_participant",
          "view:study_device_configuration",
          "edit:localization",
          "view:localization",
          "create:participant",
          "edit:participant",
          "delete:participant",
          "view:participant",
          "edit:participant_status",
          "launch:assessment",
          "view:assessment_response",
          "generate:report",
          "create:user",
          "edit:user",
          "view:user",
          "deactivate:user"
        ]
      }
    ]
  }
}

Org Admin:
{
  "iss": "https://auth.dev.koneksahealth.com/",
  "sub": "0af8a27a-b522-4a0a-bd4d-ecff17959a55",
  "exp": 1668002514,
  "nbf": 1668002214,
  "iat": 1668002214,
  "jti": "a8990dc0-8bf0-4b08-b588-295b7b92264e",
  "koneksa/passport": {
    "i": "0af8a27a-b522-4a0a-bd4d-ecff17959a55",
    "r": [
      {
        "id": "6d83ab98-966c-5db1-8676-71b8cdf59c27",
        "n": "self",
        "s": {
          "oid": "964bb7cb-017c-5a35-bcf1-5ddc8f958cb5",
          "iid": "0af8a27a-b522-4a0a-bd4d-ecff17959a55"
        },
        "p": [
          "edit:user",
          "view:user"
        ]
      },
      {
        "id": "f6eb8578-a478-11ec-b909-0242ac120002",
        "n": "Organization Administrator",
        "s": {
          "oid": "2bbea985-40e8-4fa0-b138-bc31d753279c"
        },
        "p": [
          "edit:organization",
          "view:organization",
          "create:sponsor",
          "edit:sponsor",
          "view:sponsor",
          "create:study",
          "edit:study",
          "view:study_configuration",
          "view:study",
          "create:site",
          "edit:site",
          "associate:site",
          "view:site",
          "edit:study_device_configuration",
          "view:study_device_configuration",
          "edit:localization",
          "view:localization",
          "view:participant",
          "edit:participant_status",
          "generate:report",
          "create:user",
          "edit:user",
          "view:user"
        ]
      }
    ]
  }
}

Sponsor Admin:
{
  "iss": "https://auth.dev.koneksahealth.com/",
  "sub": "ab1ebc86-0d5e-42a8-bb62-b9e52686291a",
  "exp": 1668006464,
  "nbf": 1668006164,
  "iat": 1668006164,
  "jti": "eb7f7b27-d0d7-47ac-b41c-9d19f5ccd3af",
  "koneksa/passport": {
    "i": "ab1ebc86-0d5e-42a8-bb62-b9e52686291a",
    "r": [
      {
        "id": "d47b09d0-d0d2-5180-8916-53b889046232",
        "n": "self",
        "s": {
          "oid": "964bb7cb-017c-5a35-bcf1-5ddc8f958cb5",
          "iid": "ab1ebc86-0d5e-42a8-bb62-b9e52686291a"
        },
        "p": [
          "edit:user",
          "view:user"
        ]
      },
      {
        "id": "f6eb8708-a478-11ec-b909-0242ac120002",
        "n": "Sponsor Administrator",
        "s": {
          "oid": "2bbea985-40e8-4fa0-b138-bc31d753279c",
          "spid": "c88b4aa2-f87b-405e-a117-fe5cc290415a"
        },
        "p": [
          "edit:sponsor",
          "view:sponsor",
          "create:study",
          "edit:study",
          "view:study_configuration",
          "view:study",
          "create:site",
          "associate:site",
          "view:site",
          "edit:study_device_configuration",
          "view:study_device_configuration",
          "edit:localization",
          "view:localization",
          "view:participant",
          "edit:participant_status",
          "generate:report",
          "create:user",
          "view:user",
          "deactivate:user"
        ]
      }
    ]
  }
}

Site Coordinator
{
  "iss": "https://auth.dev.koneksahealth.com/",
  "sub": "ec5e62dd-2c26-468c-a08a-415f52e2a69b",
  "exp": 1668009547,
  "nbf": 1668009247,
  "iat": 1668009247,
  "jti": "be23d2cc-5c45-4248-beb8-0a5088c66d59",
  "koneksa/passport": {
    "i": "ec5e62dd-2c26-468c-a08a-415f52e2a69b",
    "r": [
      {
        "id": "09f040ed-b085-5e5b-b51f-ec4bb5d3bd62",
        "n": "self",
        "s": {
          "oid": "964bb7cb-017c-5a35-bcf1-5ddc8f958cb5",
          "iid": "ec5e62dd-2c26-468c-a08a-415f52e2a69b"
        },
        "p": [
          "edit:user",
          "view:user"
        ]
      },
      {
        "id": "f6eb91da-a478-11ec-b909-0242ac120002",
        "n": "Site Coordinator",
        "s": {
          "oid": "2bbea985-40e8-4fa0-b138-bc31d753279c",
          "spid": "c88b4aa2-f87b-405e-a117-fe5cc290415a",
          "stid": "8938a323-06a3-478f-a0ad-497b1bf655d5",
          "siid": "366663e4-2f2e-4082-99fe-a06e44145b5c"
        },
        "p": [
          "view:site",
          "assign:device_participant",
          "unassign:device_participant",
          "create:participant",
          "edit:participant",
          "view:participant",
          "edit:participant_pii",
          "view:participant_pii",
          "edit:participant_status",
          "launch:assessment",
          "view:assessment_response",
          "generate:report",
          "create:participant_pii"
        ]
      }
    ]
  }
}
*/
