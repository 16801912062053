'use client';

import {KIconKnowledgeCenter} from '@/components/icons/fa';
import colors from '@/styles/colors';
import {IconButton} from '@mui/material';

/* ? Icon button linking to the help knowledge center */
export function KnowledgeCenterIconButton() {
  return (
    <IconButton
      edge="end"
      aria-label="Link to Knowledge Center"
      sx={{marginRight: '4px'}}
      href={'/help'}
      target={'Knowledge Center'}
      data-testid={'link-knowledge-center'}
    >
      <KIconKnowledgeCenter size={'sm'} color={colors.pagination} />
    </IconButton>
  );
}
