'use client';
import {useGlobalUIContext} from '@/components/providers/GlobalUIProvider';
import MenuIcon from '@mui/icons-material/Menu';
import {IconButton} from '@mui/material';

/**
 * Hamburger button icon that toggles the navigation menu on the left-hand side.
 *
 * @param {IconButtonProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const LeftNavDrawerToggleButton = () => {
  const {toggleMainMenu} = useGlobalUIContext();

  return (
    <IconButton
      edge="start"
      data-testid="toggle-main-menu"
      aria-label="main menu collapse toggle"
      onClick={toggleMainMenu}
    >
      <MenuIcon />
    </IconButton>
  );
};
