'use client';
import {NotificationTags} from '@/app/(authenticated-pages)/notifications/_notifications/NotificationTags';
import {NotificationsList} from '@/app/(authenticated-pages)/notifications/_notifications/NotificationsList';
import {KIconBell} from '@/components/icons/fa';
import {KRow} from '@/components/layout/KRow';
import {KMenu, KMenuProps} from '@/components/menu/KMenu';
import {
  NotificationMessage,
  getNotificationsDefaultFetchParams,
  useGetNotifications,
} from '@/hooks/useGetNotifications';
import {Badge, Button, IconButton} from '@mui/material';
import {useRouter} from 'next/navigation';
import {FC, useMemo} from 'react';

export type NotificationBellInboxButtonProps = Omit<KMenuProps, 'items'>;

/**
 * Notifications list of max 3 notifications as popup extending KMenu and using NotificationsList.
 *
 * Clicking button routes to notifications center.
 * Clicking notification routes to notifications center with notification selected.
 *
 * @see NotificationsList
 */
export const NotificationBellInboxButton: FC<NotificationBellInboxButtonProps> = ({...menuProps}) => {
  const params = useMemo(() => ({...getNotificationsDefaultFetchParams, limit: 3, isRead: false}), []);
  const {messages, hasUnread} = useGetNotifications(params);
  const router = useRouter();
  const route = (message?: NotificationMessage) => `/notifications${message ? `?id=${message.id}` : ''}`;

  const onClickNotification = (message?: NotificationMessage) => {
    router.push(route(message));
  };

  const hideBadge = !hasUnread;

  return (
    <KMenu
      menuCardProps={{sx: {width: 365}}}
      popover={
        <KRow sx={{width: 34}}>
          <Badge color="error" variant="dot" invisible={hideBadge} data-testid={`notifications-badge-${!hideBadge}`}>
            <IconButton edge="end" aria-label="notifications toggle" data-testid={'link-notifications'}>
              <KIconBell size={'sm'} color={'#738c9a'} />
            </IconButton>
          </Badge>
        </KRow>
      }
      isClickToOpen
      {...menuProps}
      items={[]}
    >
      <>
        <NotificationsList
          variant={'preview'}
          messages={messages}
          onClickNotification={onClickNotification}
          viewProps={{headerBadges: [NotificationTags]}}
        />

        <Button
          color={'primary'}
          sx={{width: '100%', borderRadius: 0}}
          onClick={() => onClickNotification()}
          data-testid={'button-navigate-notification-center'}
        >
          Notification Center
        </Button>
      </>
    </KMenu>
  );
};
