import {KButtonLink} from '@/components/buttons/KButtonLink';
import {KCard, KCardProps} from '@/components/layout/KCard';
import {KRow} from '@/components/layout/KRow';
import {KStack} from '@/components/layout/KStack';
import {SanitizedHTMLContainer} from '@/components/layout/SanitizedHTMLContainer';
import {KBreadcrumbs} from '@/components/navigation/KBreadcrumbs';
import {NotificationMessage} from '@/hooks/useGetNotifications';
import {
  BreadcrumbScope,
  useMemoScopedBreadcrumbs,
  validResourceTreeScopeSchema,
} from '@/hooks/useMemoScopedBreadcrumbs';
import {shortMonthDateDisplayFormat} from '@/util/time';
import {DateTime} from 'luxon';
import {FC} from 'react';
import {NotificationTags} from './NotificationTags';
import {NotificationBadgeProps} from './types';

export type NotificationCardVariants = 'preview' | 'list' | 'expanded';

export type NotificationCardProps<
  L extends NotificationBadgeProps = NotificationBadgeProps,
  R extends NotificationBadgeProps = NotificationBadgeProps,
  A extends NotificationBadgeProps = NotificationBadgeProps,
  H extends NotificationBadgeProps = NotificationBadgeProps,
> = KCardProps & {
  variant: NotificationCardVariants;
  message: NotificationMessage;
  leftBadges?: FC<L>[];
  rightBadges?: FC<R>[];
  actionBadges?: FC<A>[];
  headerBadges?: FC<H>[];
  onChangeMessage?: (message: NotificationMessage) => void;
};

function entityDashboardLinkButtonTextFromScope(scope: BreadcrumbScope) {
  switch (true) {
    case !!scope.participant:
      return 'Participant Dashboard';
    case !!scope.site:
      return 'Site Dashboard';
    case !!scope.study:
      return 'Study Dashboard';
    case !!scope.sponsor:
      return 'Sponsor Dashboard';
    case !!scope.organization:
      return 'Organization Dashboard';
    default:
      return 'Go to Dashboard';
  }
}

/**
 * Notification view extending KCard with variants 'preview', 'list' and 'expanded'.
 * Renders header with message title and created date formatted using shortMonthDateDisplayFormat.
 * Renders content with message body.
 *
 * @see card.theme.tsx with MuiCard variant 'notification' for styling.
 * @see NotificationReadUnread
 */
export const NotificationCard: FC<NotificationCardProps> = ({
  variant,
  message,
  leftBadges,
  rightBadges,
  actionBadges,
  headerBadges,
  onChangeMessage,
  'data-testid': tid,
  ...cardProps
}) => {
  // If message has a valid scope, generate the expected breadcrumbs
  const messageScopeValidationResponse = validResourceTreeScopeSchema.safeParse(message.scope);
  /** will be `[] | [Org] | [Org, Sponsor] | [Org, Sponsor, Study] | [Org, Sponsor, Study, Site] |
  [Org, Sponsor, Study, Site, Participant]` */
  const breadcrumbDefs = useMemoScopedBreadcrumbs(
    messageScopeValidationResponse.success ? messageScopeValidationResponse.data : {},
  );
  const currentEntityBreadcrumb = breadcrumbDefs[breadcrumbDefs.length - 1];

  const resolveBadges = (badges: FC<NotificationBadgeProps>[]) =>
    badges.map((Badge, index) => <Badge key={index} message={message} onChangeMessage={onChangeMessage} />);

  return (
    <KCard
      variant="notification"
      {...cardProps}
      data-testid={tid}
      className={`view ${variant} ${cardProps.className ?? ''}`}
      title={
        <KRow className={'notification-header'} title={message.title}>
          <div className={'notification-title'} data-testid={`${tid}-title`}>
            {message.title}
          </div>

          {headerBadges && (
            <div className={'notification-badges-header'} data-testid={`${tid}-badges-header`}>
              {resolveBadges(headerBadges)}
            </div>
          )}

          <div className={'notification-created'} data-testid={`${tid}-created`}>
            {DateTime.fromISO(message.created).toFormat(shortMonthDateDisplayFormat)}
          </div>
        </KRow>
      }
    >
      {leftBadges && (
        <div className={'notification-badges-left'} data-testid={`${tid}-badges-left`}>
          {resolveBadges(leftBadges)}
        </div>
      )}

      {rightBadges && (
        <div className={'notification-badges-right'} data-testid={`${tid}-badges-right`}>
          {resolveBadges(rightBadges)}
        </div>
      )}

      {actionBadges && (
        <span className={'notification-actions'} data-testid={`${tid}-badges-actions`}>
          {resolveBadges(actionBadges)}
        </span>
      )}

      <KStack gap={2}>
        <KBreadcrumbs className={'notification-breadcrumb'} crumbs={breadcrumbDefs.slice(0, -1)} />

        <SanitizedHTMLContainer
          className={'notification-body'}
          component={'div'}
          data-testid={`${tid}-body`}
          htmlContent={message.body}
        />

        {currentEntityBreadcrumb?.href && messageScopeValidationResponse.success && (
          <KRow className={'notification-entity-dashboard-link'} justifyContent={'center'}>
            <KButtonLink
              data-testid={`${tid}-entity-dashboard-link`}
              href={currentEntityBreadcrumb.href}
              buttonText={entityDashboardLinkButtonTextFromScope(messageScopeValidationResponse.data)}
            />
          </KRow>
        )}

        <NotificationTags variant={'expanded'} message={message} />
      </KStack>
    </KCard>
  );
};
