import {ScopeForPermission, hasPermissionOnResource, hasPlatformWidePermission} from '@/services/permissions';
import {useUser} from '@auth0/nextjs-auth0/client';
import {Permission} from '@koneksahealth/ts-authorizer';
import {useCallback, useMemo} from 'react';

export function useProfile() {
  const {user} = useUser();

  return user! ?? {};
}

export type Ability = ReturnType<typeof usePermissions>;
/**
 * Hook used to check against the current user's permissions.
 *
 * @example
 * const ability = usePermissions();
 *
 * if (ability.can('edit:study', {orgId, sponsorId, studyId})) {
 *   return <a href="/take/me/to/the/study/edit/page">Lets go!</a>
 * } else {
 *   return <div>Access Denied</div>
 * }
 */
export function usePermissions() {
  const {passportClaim} = useProfile();

  const can = useCallback(
    <T extends Permission>(action: T, scope: ScopeForPermission<T>) =>
      passportClaim ? hasPermissionOnResource(passportClaim, action, scope) : false,
    [passportClaim],
  );

  const canPlatformWide = useCallback(
    <T extends Permission>(action: T) => (passportClaim ? hasPlatformWidePermission(passportClaim, action) : false),
    [passportClaim],
  );

  const ability = useMemo(
    () => ({
      /**
       * Returns a boolean indicating that the current user has the appropriate permissions to perform the specified
       * action against the specified resource.
       */
      can,
      /**
       * Returns a boolean indicating that the current user has the appropriate permissions to perform the specified
       * action against the entire platform (e.g. Super Administrator Rights)
       */
      canPlatformWide,
    }),
    [can, canPlatformWide],
  );

  return ability;
}
