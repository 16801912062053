'use client';
import {KMenu} from '@/components/menu/KMenu';
import type {KMenuItemProps} from '@/components/menu/KMenuItem';

/**
 * Platform navigation drawer menu.
 */
export const LeftNavDrawerMenu = ({menuItems}: {menuItems: KMenuItemProps[]}) => (
  <KMenu menuListProps={{classes: {root: 'navBarMenu'}}} items={menuItems} />
);
