'use client';
import {AppBar, Toolbar} from '@mui/material';
import {PropsWithChildren} from 'react';

export function KAppBar({children}: PropsWithChildren) {
  return (
    <nav className={'appBar'}>
      <AppBar position="static">
        <Toolbar sx={{columnGap: 2}}>{children}</Toolbar>
      </AppBar>
    </nav>
  );
}
