export const timeDateDisplayFormat = 'MMM d, yyyy - h:mm a' as const;
export const timeDateDisplayFormatNoDashWithTimeZone = 'MMM d, yyyy h:mm a (ZZZZ)' as const;
export const timeDateDisplayWithTimezone = `${timeDateDisplayFormat} (ZZZZ)` as const;
export const fullDateTimeDisplayFormat = "MMM d, yyyy 'at' h:mm a (ZZZZ)" as const;
export const simpleDateTimeDisplayFormat = 'MMMM d, yyyy' as const;
export const shortMonthDateDisplayFormat = 'MMM d, yyyy' as const;
export const timeDisplayFormatNoTimezone = 'h:mm a' as const;
export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const DAY_IN_MINUTES = 1440;
export const DAY_IN_MILLISECONDS = DAY_IN_SECONDS * 1000;
