import {BreadcrumbDefinition} from '@/components/navigation/KBreadcrumbs';
import {usePermissions} from '@/hooks/auth';
import {StudySite} from '@/hooks/graphql/studies';
import {buildURL} from '@/util/url';
import {useMemo} from 'react';
import * as z from 'zod';

export function getStudySiteName(studySite: StudySite) {
  return `${studySite?.referenceNumber}-${studySite?.site.name}`;
}

const idAndNameSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});
export const validResourceTreeScopeSchema = z.union([
  z.object({
    organization: idAndNameSchema.optional(),
    sponsor: z.undefined().optional(),
    study: z.undefined().optional(),
    site: z.undefined().optional(),
    participant: z.undefined().optional(),
  }),
  z.object({
    organization: idAndNameSchema,
    sponsor: idAndNameSchema,
    study: z.undefined().optional(),
    site: z.undefined().optional(),
    participant: z.undefined().optional(),
  }),
  z.object({
    organization: idAndNameSchema,
    sponsor: idAndNameSchema,
    study: idAndNameSchema,
    site: z.undefined().optional(),
    participant: z.undefined().optional(),
  }),
  z.object({
    organization: idAndNameSchema,
    sponsor: idAndNameSchema,
    study: idAndNameSchema,
    site: idAndNameSchema,
    participant: z.undefined().optional(),
  }),
  z.object({
    organization: idAndNameSchema,
    sponsor: idAndNameSchema,
    study: idAndNameSchema,
    site: idAndNameSchema,
    participant: idAndNameSchema.optional(),
  }),
]);

export type BreadcrumbScope = z.infer<typeof validResourceTreeScopeSchema>;

export type GetScopedBreadcrumbsProps = {
  /** Adds a `Home` breadcrumb to the homepage path */
  homePagePath?: string;
  /**
   * If true, the last autogenerated
   * breadcrumb will not be clickable. This is used typically to indicate you are currently on this page
   */
  dropHrefOnLastCrumb?: boolean;
  additionalBreadcrumbs?: BreadcrumbDefinition[];
} & BreadcrumbScope;

/**
 * A helper function for building up the breadcrumb definitions typically used for org -> sponsor -> study -> site -> participant
 * pages. Should typically be used in `useMemo` and can take additional breadcrumbs to append to the end of the generated list.
 *
 * NOTE that additional breadcrumbs MUST BE STATIC IN LENGTH if provided during an individual render.
 */
export function useMemoScopedBreadcrumbs({
  homePagePath,
  dropHrefOnLastCrumb,
  additionalBreadcrumbs = [],
  ...scope
}: GetScopedBreadcrumbsProps): BreadcrumbDefinition[] {
  const ability = usePermissions();
  return useMemo(() => {
    const breadcrumbs: BreadcrumbDefinition[] = [];

    if (homePagePath) {
      breadcrumbs.push({text: 'Home', href: homePagePath});
    }

    let scopePath = '';
    if (scope.organization) {
      const orgScope = {orgId: scope.organization.id};
      scopePath += buildURL('/orgs/[orgId]', undefined, orgScope);
      breadcrumbs.push({
        text: scope.organization.name,
        href: ability.can('view:ui_organization_dashboard', orgScope) ? scopePath : undefined,
      });
    }

    if (scope.sponsor) {
      const sponsorScope = {orgId: scope.organization.id, sponsorId: scope.sponsor.id};
      scopePath += buildURL('/sponsors/[sponsorId]', undefined, sponsorScope);
      breadcrumbs.push({
        text: scope.sponsor.name,
        href: ability.can('view:ui_sponsor_dashboard', sponsorScope) ? scopePath : undefined,
      });
    }

    if (scope.study) {
      const studyScope = {orgId: scope.organization.id, sponsorId: scope.sponsor.id, studyId: scope.study.id};
      scopePath += buildURL('/studies/[studyId]', undefined, studyScope);
      breadcrumbs.push({
        text: scope.study.name,
        href: ability.can('view:ui_study_dashboard', studyScope) ? scopePath : undefined,
      });
    }

    if (scope.site) {
      const studySiteScope = {
        orgId: scope.organization.id,
        sponsorId: scope.sponsor.id,
        studyId: scope.study.id,
        siteId: scope.site.id,
      };
      scopePath += buildURL('/sites/[siteId]', undefined, studySiteScope);
      breadcrumbs.push({
        text: scope.site.name,
        href: ability.can('view:ui_site_dashboard', studySiteScope) ? scopePath : undefined,
      });
    }

    if (scope.participant) {
      const participantScope = {
        orgId: scope.organization.id,
        sponsorId: scope.sponsor.id,
        studyId: scope.study.id,
        siteId: scope.site.id,
        identityId: scope.participant.id,
      };
      scopePath += buildURL('/participants/[identityId]', undefined, participantScope);
      breadcrumbs.push({
        text: scope.participant.name,
        href: ability.can('view:participant', participantScope) ? scopePath : undefined,
      });
    }

    if (breadcrumbs.length && dropHrefOnLastCrumb) {
      breadcrumbs[breadcrumbs.length - 1].href = undefined;
    }

    if (additionalBreadcrumbs) {
      breadcrumbs.push(...additionalBreadcrumbs);
    }

    return breadcrumbs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    homePagePath,
    dropHrefOnLastCrumb,
    scope.organization?.id,
    scope.organization?.name,
    scope.sponsor?.id,
    scope.sponsor?.name,
    scope.study?.id,
    scope.study?.name,
    scope.site?.id,
    scope.site?.name,
    scope.participant?.id,
    scope.participant?.name,
    // spread the additional breadcrumbs text and hrefs in to control if we need to rebuild our breadcrumbs
    // this will error if the length of additional breadcrumbs changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...additionalBreadcrumbs.flatMap((ab) => [ab.text, ab.href]),
  ]);
}
