'use client';
import {KIconKLogo} from '@/components/icons';
import {KStack} from '@/components/layout/KStack';
import {KMenuItemProps} from '@/components/menu/KMenuItem';
import clsx from 'clsx';
import {useGlobalUIContext} from '../../providers/GlobalUIProvider';
import {LeftNavDrawerMenu} from './LeftNavDrawerMenu';

/**
 * The left nav menu sidebar layout
 */
export const LeftNavDrawer = ({menuItems}: {menuItems: KMenuItemProps[]}) => {
  const {state} = useGlobalUIContext();

  return (
    <nav className={clsx('navBar', state.collapseMenu && 'collapse-menu')}>
      <KStack className={'navMenuClipContainer'}>
        <div className={'logoClipContainer'}>
          <KIconKLogo />
        </div>

        <LeftNavDrawerMenu menuItems={menuItems} />
      </KStack>
    </nav>
  );
};
