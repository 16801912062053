import {KIconChevronRight} from '@/components/icons/fa';
import {Breadcrumbs, Typography} from '@mui/material';
import kebabCase from 'lodash.kebabcase';
import Link from 'next/link';

export type BreadcrumbDefinition = {
  href?: string;
  text: string;
};

export type KBreadcrumbsProps = {
  className?: string;
  crumbs: BreadcrumbDefinition[];
};

export function KBreadcrumbs({crumbs, ...rest}: KBreadcrumbsProps) {
  return (
    <Breadcrumbs separator={<KIconChevronRight fontSize="small" />} aria-label="breadcrumb" {...rest}>
      {crumbs.map(({href, text}) => (
        <Typography
          key={`${text}-${href}`}
          sx={{
            color: 'inherit',
            opacity: 1,
            cursor: href && 'pointer',
            fontSize: '14px',
          }}
          data-href={href}
          data-testid={`breadcrumb-${kebabCase(text)}`}
          {...(href && {
            component: Link,
            prefetch: false,
            href,
          })}
        >
          {text}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}
