const dummyDomain = 'https://dummy.koneksahealth.com';

/**
 * NextJSURLToParametersObject
 * @desc Recursively converts all NextJS path parameters in a string into an object of expected parameter definitions.
 *
 * @example
 * type Path = '/myRoute/[param]/[...anotherParam]'
 *
 * // Expect {param: string, anotherParam: string[]}
 * type NewPath = NextJSURLToParametersObject<Path>
 */
// prettier-ignore
export type NextJSURLToParametersObject<Path extends string> =
  Path extends `${string}[${infer Param}]${infer Suffix}`
    ? Param extends `...${infer P}`
      ? {[Key in P]: string[]} & NextJSURLToParametersObject<Suffix>
      : {[Key in Param]: string} & NextJSURLToParametersObject<Suffix>
    : {};

// `URL.canParse` method polyfil
// https://url.spec.whatwg.org/#dom-url-canparse

function canParse(url: string) {
  try {
    return !!new URL(url);
  } catch (error) {
    return false;
  }
}

/**
 * Safely builds a url given a path and search params.
 * all `[variable]` statements will also be replaced by urlParams if they are provided
 */
export function buildURL<T extends string>(
  initialPath: T,
  searchParams?: Record<string, string | string[] | undefined>,
  urlParams: NextJSURLToParametersObject<T> = {} as NextJSURLToParametersObject<T>,
) {
  let path: string = initialPath;
  let url: URL;

  // Replace all [variable] url params with the provided params if they exist
  for (const key in urlParams) {
    const urlParam = urlParams[key];
    if (typeof urlParam === 'string') {
      path = path.replace(`[${key}]`, urlParam);
    } else if (Array.isArray(urlParam)) {
      path = path.replace(`[...${key}]`, urlParam.join('/'));
    }
  }

  if (canParse(path)) {
    url = new URL(path);
  } else {
    url = new URL(path, dummyDomain);
  }

  if (searchParams) {
    for (const [key, value] of Object.entries(searchParams)) {
      if (typeof value === 'string') {
        url.searchParams.append(key, value);
      } else if (Array.isArray(value)) {
        url.searchParams.append(key, value.join(','));
      }
    }
  }

  return url.toString().replace(dummyDomain, '');
}
