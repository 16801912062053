'use client';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {Avatar} from '@mui/material';
import {FC} from 'react';

import {KRow, KRowProps} from '@/components/layout/KRow';
import {KMenu} from '@/components/menu/KMenu';
import type {KMenuItemProps} from '@/components/menu/KMenuItem';
import {useProfile} from '@/hooks/auth';

/** profile menu with icons. captions and links */
const profilePopupMenu: KMenuItemProps[] = [
  {
    icon: <PersonIcon />,
    text: 'Account Settings',
    href: '/account/account-settings',
    'data-testid': 'link-account-settings',
  },
  {
    icon: <LogoutIcon />,
    text: 'Sign Out',
    href: '/api/auth/logout',
    'data-testid': 'link-sign-out',
    hrefIsNotNextJSPage: true,
  },
];

export type AvatarMenuButtonProps = KRowProps & {
  /** test id */
  'data-testid': string;
};

/**
 * User Profile icon and drop down menu with links.
 *
 */
export const AvatarMenuButton: FC<AvatarMenuButtonProps> = (props) => {
  const user = useProfile();
  const initials = `${user.given_name?.charAt(0) ?? ''}${user.family_name?.charAt(0) ?? ''}`.toUpperCase();
  return (
    <KMenu
      popover={
        <KRow {...props}>
          <Avatar style={{cursor: 'pointer'}}>{initials}</Avatar>
        </KRow>
      }
      items={profilePopupMenu}
      isClickToOpen
    />
  );
};
