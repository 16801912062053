export async function attempt<T, E extends Error & {[key: string]: any}>(
  p: PromiseLike<T>,
): Promise<[E | undefined, T]> {
  try {
    const result = await p;

    return [undefined, result];
  } catch (e) {
    return [e as E, {} as T];
  }
}

export function attemptSync<T extends (...args: any[]) => any, E extends Error & {[key: string]: any}>(
  p: T,
): [E | undefined, ReturnType<T>] {
  try {
    const result = p();

    return [undefined, result as ReturnType<T>];
  } catch (e) {
    return [e as E, {} as ReturnType<T>];
  }
}
