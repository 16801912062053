'use client';
import {KIconEnvelope, KIconHome, KIconUser} from '@/components/icons';
import type {KMenuItemProps} from '@/components/menu/KMenuItem';
import {LeftNavDrawer} from '@/components/navigation/navdrawer/LeftNavDrawer';
import {useGlobalUIContext} from '@/components/providers/GlobalUIProvider';
import {usePermissions} from '@/hooks/auth';
import {useMemo} from 'react';

type LeftNavDrawerMenuProps = {
  homepage?: string;
  hideNavDrawerMenuItems?: boolean;
};

/**
 * Standard platform navigation drawer menu.
 */
export const LeftNavDrawerMenu = ({homepage, hideNavDrawerMenuItems}: LeftNavDrawerMenuProps) => {
  const ability = usePermissions();
  const {state} = useGlobalUIContext();

  const menu = useMemo(() => {
    if (hideNavDrawerMenuItems) {
      return [];
    }

    const mainMenu: KMenuItemProps[] = [
      {
        icon: <KIconHome />,
        text: 'Home',
        href: homepage || '/',
        'data-testid': 'link-dashboard',
      },
    ];

    if (state.inboxEnabled) {
      mainMenu.push({
        icon: <KIconEnvelope />,
        text: 'Notification Center',
        href: '/notifications',
        'data-testid': 'link-notifications',
      });
    }

    // If super admin, can add users table
    if (ability.canPlatformWide('view:user')) {
      mainMenu.push({
        icon: <KIconUser />,
        text: 'Users',
        href: '/users',
        'data-testid': 'link-users',
      });
    }

    return mainMenu;
  }, [ability, state.inboxEnabled, homepage, hideNavDrawerMenuItems]);

  return <LeftNavDrawer menuItems={menu} />;
};
