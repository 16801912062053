import {IGetMessagesParams} from '@trycourier/client-graphql/typings/src/messages';
import {IInboxMessagePreview, useInbox} from '@trycourier/react-hooks';
import {useEffect, useState} from 'react';

/** todo just a placeholder to build out components */
export type NotificationMessage = {
  id: string;
  created: string;
  title: string;
  body: string;
  read?: boolean;
  archived?: boolean;
  tags?: string[];
  // todo data members
  data?: object;
  scope?: {
    organization: {id: string; name: string};
    sponsor?: {id: string; name: string};
    study?: {id: string; name: string};
    site?: {id: string; name: string};
    participant?: {id: string; name: string};
  };
};

export interface KIGetMessagesParams extends IGetMessagesParams {
  /** UI only */
  isInbox?: boolean;
  archived?: boolean;
}

export const getNotificationsDefaultFetchParams: KIGetMessagesParams = {limit: 100};

function inboxMessageToNotificationMessage(msg: IInboxMessagePreview): NotificationMessage {
  const data = msg.data || undefined;
  const scope = data?.scope || {};
  const organization = {id: scope.organization_id, name: scope.organization_name};
  const sponsor =
    scope.sponsor_id && scope.sponsor_name ? {id: scope.sponsor_id, name: scope.sponsor_name!} : undefined;
  const study = scope.study_id && scope.study_name ? {id: scope.study_id, name: scope.study_name!} : undefined;
  const site = scope.site_id && scope.site_name ? {id: scope.site_id, name: scope.site_name!} : undefined;
  // todo api is not sending atm but required for dashboard
  const participant = scope.participant_id ? {id: scope.participant_id, name: scope.participant_name ?? ''} : undefined;

  return {
    ...msg,
    id: msg.messageId,
    body: msg.preview || '',
    created: msg.created,
    title: msg.title || '',
    read: msg.read !== null,
    archived: msg.archived !== null,
    // @ts-ignore todo getting tags not member of courier message but it's in the docs and data
    tags: msg.tags,
    data,
    scope:
      Object.keys(scope).length > 0
        ? {
            organization,
            sponsor,
            study,
            site,
            participant,
          }
        : undefined,
  };
}

/**
 * Wrapper to useInbox() with message transform and maintains active messages list.
 **/
export function useGetNotifications(params: KIGetMessagesParams = getNotificationsDefaultFetchParams) {
  const inbox = useInbox();
  const inboxMessages = inbox.messages || [];

  const [messages, setMessages] = useState<NotificationMessage[]>(() =>
    inboxMessages.map(inboxMessageToNotificationMessage),
  );
  /** separate boolean state for having at least one unread messages to prevent flickering when fetching messages */
  const [hasUnread, setHasUnread] = useState(() => inboxMessages.some((msg) => !msg.read && !msg.archived));

  useEffect(() => {
    const {isInbox, ...filters} = params;
    setMessages([]);
    inbox.fetchMessages({params: filters});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (messages.length !== inboxMessages.length) {
      const msgs = inbox.messages?.map(inboxMessageToNotificationMessage);

      setMessages(msgs ?? []);
      setHasUnread(inboxMessages.some((msg) => !msg.read && !msg.archived));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inbox.messages]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeMessage = (message: NotificationMessage) => {
    // delete archived message from list
    if (message.archived) {
      const index = messages?.findIndex((msg) => msg.id === message.id);
      messages.splice(index, 1);
    }

    setMessages([...messages]);
    setHasUnread(messages.some((msg) => !msg.read && !msg.archived));
  };

  return {messages, setMessages, onChangeMessage, inbox, hasUnread} as const;
}
