import Link from 'next/link';
import {KButton, KButtonProps} from './KButton';

export type KButtonLinkProps = KButtonProps & {
  href: string;
};

export function KButtonLink({href, ...kbuttonProps}: KButtonLinkProps) {
  return <KButton {...kbuttonProps} {...{href, component: Link, prefetch: false}} />;
}
