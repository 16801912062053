'use client';
import {Typography, TypographyProps} from '@mui/material';
import DOMPurify from 'dompurify';
import {useEffect, useState} from 'react';

export type SanitizedHTMLContainerProps = TypographyProps & {
  htmlContent: string | null | undefined;
  'data-testid': string;
  /** @deprecated Use `htmlContent` prop to set the sanitized html content */
  dangerouslySetInnerHTML?: never;
};

/** SanitizedHTMLContainer allows us to render rich html inside a component */
export const SanitizedHTMLContainer = ({htmlContent, ...props}: SanitizedHTMLContainerProps) => {
  const [mounted, setMounted] = useState(false);

  // this effect is here to trigger a re-render using the DOMPurify sanitize on the content passed in, if this is SSRed we
  // do not want to call it, just make it an empty string
  useEffect(() => {
    setMounted(true);
  }, []);

  // only render inner HTML on the client since otherwise we'd require jsdom on the server side
  const text = mounted ? DOMPurify.sanitize(htmlContent || '', {USE_PROFILES: {html: true}}) : '';
  return (
    <Typography
      component="div"
      {...props}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};
